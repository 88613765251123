<template>
  <div class="home">
    <img class="logo" alt="logo" src="../assets/logo.png">
    <p class="text-center">Coming Soon</p>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home'
}
</script>

<style>
.text-center {
  text-align: center;
  font-family: 'RobotoSlab';
  font-size: 72px;
}
</style>
